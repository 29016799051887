.main {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: calc(100% - 60px);
    -webkit-font-smoothing: antialiased;
    background-color: #F1F2F6;
}
.top_bar{
    display: flex;
    height:60px;
}
.minimizeVideo{
     width: 0px;
     height: 0px
}

.maximizeVideo{
    width: 600px;
     height: 400px
}

.videoHeader1{
    display: flex;
    position: absolute;
    z-index: 9;
    width: 600px;
    height: 30px;
    top: 800px;
    align-items: center;
    flex-direction: row;
    background-color: black;
    justify-content: space-evenly;
}

.microphone{
    color: white
}

.chatsList {
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    border-left: none;
}
.requestDiv{
    height:180px;
    align-self: center;
    align-items: center;
    padding-left: 200px;
}
.block {
    height: 100%;
}

.list_body{
    position: relative;
    height: 100%;
    background-color: #F1F2F6;
    padding-left: 5px;
    padding-right: 5px;
}

.msg_body {
    position: relative;
    box-sizing: border-box;
    height: calc(100% - 118px);
    background-color: #F1F2F6;
    border-color: rgb(221, 226, 230);
}

.msg_header,.msg_header_default, .msg_header_red_banner {
    font-size: 12px;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 88px;
    z-index: 2;
    text-align: center;
    /* box-sizing: border-box; */
    background-color: #E3E3EA;
    border-radius: 6px;
    /* margin-top: 15px; */
}
.msg_header_default{
    justify-content: center !important;
}
.msg_header_red_banner{
    background-color: red;
    color:white;
    font-weight: 700
}

.msg_header_red {
    font-size: 12px;
    font-weight: 600;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    position: relative;
    height: 107px;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(226, 12, 12);
    border-bottom: 1px solid rgb(221, 226, 230);
}
.msg_header_red{
    height: 52px !important;
}
.list_header {
    /* margin-top: 15px; */
    /* margin-bottom: 15px; */
    /* background-color: #E3E3EA; */
    border-radius: 6px;
    /* float: none; */
    /* display: flex; */
    height: 118px;
    align-items: center;
    display: flex;
}

.header_body{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgb(203, 212, 222);
}

.header_text {
    font-size: 20px;
    font-weight: 400;
    color: #424D57;
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 20px);
    left: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0px 10px;
    font-family: 'HarmoniaSansStd-Bold';

}
.header_red {
    font-size: 18px;
    font-weight: bold;
    color: rgb(241, 242, 243);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 20px);
    left: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0px 10px;
}

.header_close {
    height: 30px;
    right: 25px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    font-weight:700;
    font-size:14px;
    top: 15px;

}
.header_status{
    padding-top: 22px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
}

.header_doctor_name{
    margin-top: 14px;
    padding-top: 8px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    border-right: rgb(203, 212, 222) 1px solid;
}
.header_transfer {
    height: 30px;
    right: 311px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    top: 15px;
    border-right: 1px solid rgb(203, 212, 222);
    padding-right: 12px;
}
.header_banUser {
    height: 30px;
    right: 99px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    top: 15px;
    border-right: 1px solid rgb(203, 212, 222);
    padding-right: 12px;
}
.header_rate {
    height: 30px;
    right: 213px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    top: 15px;
    border-right: 1px solid rgb(203, 212, 222);
    padding-right: 12px;
}

.video_show{ width: "600px"; height: "400px"}
.video_hide{ width: "0px"; height: "0px"}
.header_video{
    height: 30px;
    right: 475px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    top: 15px;
    border-right: 1px solid rgb(203, 212, 222);
    padding-right: 12px;
}
.one{
    height: 20px;
    margin: -20px 0px 0px 178px;
}
.videoMainView{
    height: 40px;
}

.assign_list_view {
    position: absolute;
    top: 45px;
    right: 408px;
    display: block;
    z-index: 2071;
    overflow: visible;
}

.main_assign_list {
    min-width: 340px;
    color: rgb(33, 37, 41);
    background-clip: padding-box;
    font-weight: 400;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 2px 5px 20px 0px;
    position: relative;
    width: 300px;
    border-radius: 4px;
    padding: 5px;
}

.main_assign_list p{
    text-align: center;
    font-size: 14px;
}

.main_assign_list ul{
    max-height: 224px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.main_assign_list ul li{
    position: relative;
    list-style: none;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    padding: 8px 10px;
    -webkit-box-align: center;
    align-items: center;
}

.main_assign_list ul li:hover{
    background-color: #e1e9ec;
}

.main_assign_view {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    width: 100%;
}

.main_assign_inner_view {
    width: 100%;
    text-overflow: ellipsis;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: flex;
    line-height: 1.3;
    vertical-align: middle;
    overflow: hidden;
}

.main_assign_inner_view img{
    width: 30px;
    margin-right: 6px;
    border-radius: 50%;
}

.online_style {
    background-color: rgb(70, 183, 118);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    left: 30px;
    top: 8px;
}

.main_assign_content {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 30px);
    overflow: hidden;
    flex-direction: column;
    margin-left: 5px;
}

.main_transfer_button {
    margin-left: 5px;
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    flex-direction: column;
}

.main_transfer_button button {
    background-color: #007eff;
    padding: 5px 15px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
}

.name_text{
    color: #424d59;
    font-size: 15px;
}

.email_text {
    color: #424d59;
    font-size: 13px;
    opacity: 0.5;
}

.chat_time{
    font-size: 16px;
    color: #df7567;
}

.chat_time_selected{
    font-size: 16px;
    color: white;
}
.chat_time_section{
    justify-content: space-evenly !important;
    align-items: center !important;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    width: 20%;
    justify-content: space-around;
    align-items: flex-end;
}


.list_view{
    display: flex;
    padding: 6px 9px;
    margin: 3px 5.5px;
    border-radius: 10px;
    /* border-width: 1px; */
    background-color: #FFFFFF;
    /* border-color: #979797; */
    /* border-style: solid; */
    cursor: pointer;
}
.unread_list_view{
    border-color: #DF7467;
    border-width: 2px;

    display: flex;
    padding: 6px 9px;
    margin: 3px 5.5px;
    border-radius: 10px;
    background-color: #FFFFFF;
    border-style: solid;
    cursor: pointer;
}
.queue_list_view{
    display: flex;
    padding: 14px 9px;
    margin: 3px 5.5px;
    border-radius: 10px;
    /* border-width: 1px; */
    background-color: #FFFFFF;
    /* border-color: #979797; */
    /* border-style: solid; */
    cursor: pointer;

}
.selected_list_view {
    display: flex;
    padding: 15px 15px;
    margin: 5px;
    background-color: #4384f5;
    border-radius: 10px;
    cursor: pointer;
}

.list_img_style {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.list_unread_style {
    position: relative;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    width: 25px;
    transition: background-color 0.5s ease 0s;
}
.unread_msg_style{
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background:#4384F5;
}
.time_style{
    font-size: 12px;
    color:#4B5762;
    font-family: 'HarmoniaSansStd-Regular';
    text-align: right;
}
.list_img {
    display: block;
    background-size: cover;
    background-image: url('../../assets/download.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
}

.selected_list_img {
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 25px;
    width: 25px;
    transition: background-color 0.5s ease 0s;
}

.selected_user_img{
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(88, 101, 142);
}

.message_indicator{
    border-radius: 50%;
    background: #DF7467;
    width: 15px;
    display: block;
    height: 15px;
}

.list_item {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 68%;
    margin-left: 10px;
}
.list_item_second {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    width: 32%;
    justify-content: space-around;
    align-items: flex-end;
}
.queueTimeText {
    margin-top:2px;
    font-size: 14px;
    margin-bottom: 5px;
}

.patient_name{
    color: #424D57;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Bold';
}
.item_name{
    color: #55626D;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Regular';
}

.item_payment_status{
    font-weight: 900;
    color: #df7667;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Regular';
}
.selected_item_name {
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Bold';
}

.item_body {
    margin-bottom: 0px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 13px;
}

.selected_item_body{
    margin-bottom: 0px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 13px;
    color: #ffffff;
}

.chats {
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
    min-width: 0px;
    -webkit-box-flex: 2;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 50%;
    background-color: #F1F2F6;
}
.convo_list{
    position: relative;
    height: calc(100% - 15px);
    z-index: 0;
    overflow-y: auto;
    padding-top:15px;
    background-color: #F1F2F6;
}
.list {
    position: relative;
    height: 100%;
    z-index: 0;
    overflow-y: auto;
    /* padding-top:15px; */
    background-color: #F1F2F6;
}
.openchatlistSection{
    display: flex;
    flex-direction: row;
}
.openchatlist{
    display: flex;
    justify-content: space-between;
    margin:5px;
    margin-top: 0px;
    margin-bottom:10px;
    border-radius: 6px;
    border-width: 1px;
    text-align: left;
    padding-left: 13px;
    padding-right: 13px;
    height:43px;
    background-color:#E3E3EA;
    color: #424D57;
    font-size: 16px;
}
.subListTitleIcon{
    padding-left:5px;
    margin-bottom:2px;
    transition: all 0.4s ease;
    align-self: center;
}
.subListTitleIconRotate{
    padding-left:5px;
    margin-top:2px;
    transition: all 0.4s ease;
    transform: rotateZ(-90deg);
    align-self: center;
}
.subListTitle{
    font-size: 18px;
    font-weight: 700;
    padding-left:10px;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
    align-self: center;
}

.header_attr{
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
}
.header_attr_value{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
    color:#000000;
}
.header_attr_value_red{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
    color:#D0342C;

}
.subListCount{
    align-self: center;
    color:#000000;
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
}
.hideConvo{
    visibility:hidden;
    opacity: 0;
    height:0;
    margin-bottom: 20px;

}
.showConvo{
    visibility:visible;
    opacity:1;
    height:auto;
    margin-bottom: 15px;
}
.notAcceptingChat{
    color:rgb(226, 12, 12);
}

.chatlist {
    height: 100%;
    display: block;
}

.chatview{
    display: flex;
}

.chatwindow {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin: 0;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
}
.header_attr_default{
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
    text-align: center;
}
.chatcontent {
    overflow: scroll;
    font-size: 15px;
    flex: 1;
    background: #fff;
    display: flex;
    position: relative;

}

.chatcontainer {
    flex: 1;
}

ul {
    list-style: none;
}

li {
    overflow-y: auto;
}

.chat_ul {
    height: 100%;
}

.chat_main_ul {
    /* padding: 20px 0 6px; */
    /* margin-bottom: 30px; */
}

.imgdiv{
    border-radius: 10px;
    margin-left:-14px;
    width: 100%;
}

.message_li {
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
    flex-direction: row;
}

.message_li_right {
    text-align: right;
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
    flex-direction: row;
}

.message_wrapper {
    padding: 10px 0 5px;
    font-size: 15px;
    overflow: auto;
    float: left;
    flex-direction: column;
}

.message_wrapper_right {
    margin-right: 40px;
    padding: 10px 0 5px;
    font-size: 15px;
    overflow: auto;
    float: right;
    flex-direction: column;
}

.author {
    display: block;
    font-size: 12px;
    line-height: 2em;
    color: rgba(66, 77, 87, 0.8);
    margin-left: 50px;
    margin-right: 50px;
}

.author_right {
    display: block;
    font-size: 12px;
    line-height: 2em;
    color: rgba(66, 77, 87, 0.8);
    margin: 0 50px 0 0;
}

.user_symbol {
    float: left;
    margin-top: 5px;
}

.user_symbol_right {
    float: none;
}

.user_img {
    position: relative;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    font-size: 13px;
    line-height: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transition: background-color 0.5s ease 0s;
}

.user_img_right{
    display: block;
    height: 100%;
}

.user_symbol_content {
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(208, 180, 84);
}

.user_symbol_content_right {
    float: right;
    position: relative;
    font-weight: normal;
    box-sizing: border-box;
    opacity: 1;
    font-size: 13px;
    line-height: 30px;
    height: 30px;
    border-radius: 50%;
    transition: background-color 0.5s ease 0s;
}

.user_symbol_content_right span{
    display: block;
    overflow: hidden;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}


.message_container {
    flex: 1;
    font-size: 15px;
    background: #fff;
    /* background: #ff9800; */
    overflow: auto;
}

.message_text {
    display: block;
    padding: 10px 20px 5px 20px;
    line-height: 1.5em;
    text-align: left;
    background: #f2f3f4;
    border-radius: 20px;
    float: left;
    max-width: 80%;
    margin: 0 0 0 8px;
    color: #424d57;
}

.message_text a p{
    color: '#4384f5';
}

.message_text_right {
    display: block;
    padding: 10px 20px 5px 20px;
    line-height: 1.5em;
    text-align: left;
    background: #4384f5;
    border-radius: 20px;
    float: right;
    max-width: 80%;
    margin: 0px 10px 0 0px;
    color: #fff;
}

.message_text_right a p{
    color: #fff;
    text-decoration-color: #fff;
}

.message_time_left {
    margin-left: 45px;
    display: flex;
    flex:1;
}

.message_time_right {
    margin-right: 45px;
    display: block;
    max-width: 100%;
}

.timestamp_span_left{
    margin-right: 10px;
    font-size: 12px;
    color: rgba(66, 77, 87, 0.8);
}
.scrollable {
    overflow-y: scroll;
}
.read_span_left {
    margin-left: 10px;
    font-size: 12px;
    color: rgba(66, 77, 87, 0.8);
}

.timestamp_span_right{
    margin-right: 10px;
    font-size: 12px;
    color: rgba(66, 77, 87, 0.8);
}

.read_span_right {
    margin-left: 10px;
    font-size: 12px;
    color: rgba(66, 77, 87, 0.8);
}

.user_symbol_img {
    display: block;
    background-size: cover;
    background-image: url('../../assets/download.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
}

.modal {
    height: 200px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
}

.cross {
    flex: 1;
    flex-direction: row;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.add {
    text-align: center;
}

.modalcontainer {
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.spandiv {
    flex: 1;
    flex-direction: column;
    margin: 5px;
}

.textarea {
    position: relative;
    /* flex: 0.25;
    margin-bottom: 0px; */
}

textarea {
    color: rgb(0, 0, 0);
    font-size: 15px;
    line-height: 1.47;
    resize: none;
    width: 100%;
    white-space: pre-wrap;
    background: transparent;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    margin: 0px;
    outline: none;
    padding: 0px;
}

.input {
    margin: 0 9px;
}

.input_container {
    display: block;
    position: relative;
    cursor: default;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(188, 198, 208);
    border-image: initial;
    border-radius: 8px;
    transition: background-color 0.2s ease 0s;
}

.disabled_input_container {
    display: block;
    position: relative;
    cursor: default;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(188, 198, 208);
    border-image: initial;
    border-radius: 8px;
    transition: background-color 0.2s ease 0s;
    background-color: rgba(66, 77, 87, 0.8);
}

.valid::placeholder {
    color: #000000;
    opacity: 0.4;
}

.inValid::placeholder {
    color: #ffffff;
}

.type_area {
    padding: 10px 12px;
}

.submit_area {
    padding: 8px 8px 8px 12px;
}

.bottom_area {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.attachment {
    color: #ffffff;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 8px;
    width: 16px;
}

.file_input {
    width: 14px;
    height: 14px;
    display: none;
}

.hashtag {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    align-self: flex-end;
    opacity: 0.4;
}

.btn_area {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
}

.btn {
    height: 34px;
    min-height: 32px;
    min-width: 32px;
    background-color: #4384f5;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
    border: 1px solid #bcc6d0;
    font-size: 15px;
    padding: 0 16px;
    text-align: center;
}

.queuedButton {
    height: 34px;
    min-height: 40px;
    min-width: 50px;
    background-color: #4384f5;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    border: 1px solid #bcc6d0;
    padding: 0 16px;
    text-align: center;
}

.tag_view {
    flex: 0 0 auto;
    padding: 3px 6px;
}

.chat_tags {
    display: flex;
    /* height: 25px; */
    position: relative;
    line-height: 20px;
    padding: 5px;
    font-size: 15px;
    flex-direction: row;
    align-items: center;
}

.chat_tags_hide {
    display: none;
}

.tag {
    display: inline-block;
}

.addtag {
    color: #fff;
    margin-right: 3px;
    line-height: 21px;
    text-shadow: none;
    padding: 0 3px 0 6px;
    background: #ff9800;
    word-break: break-all;
    display: inline-flex;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.delete_tag {
    padding-left: 5px;
    cursor: pointer;
}

.add_tag {
    text-decoration: none;
    vertical-align: bottom;
    position: relative;
    top: -2px;
    color: #4384f5;
    cursor: pointer;
}

.another_tag_container {
    position: relative;
    padding: 5px 0px 5px;
    line-height: 25px;
    cursor: pointer;
}

.add_tag_icon {
    display: inline-block;
    vertical-align: middle;
    margin: -1px 3px 0 0;
    margin-top: 1px;
    cursor: pointer;
}
.select_chat_view{
    font-size:24px;
    font-size:700;
    text-align:center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    top:50%
}
.add_icon {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 17px;
    height: 17px;
    line-height: 17px;
    cursor: pointer;
}

.main_modal {
    position: relative;
    display: inline-block;
    width: 100px;
}

.main_modal_hide {
    display: none;
}

.tag_modal {
    position: absolute;
    bottom: 100%;
    left: 0%;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 2px 5px 20px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    scroll-behavior: auto;
    overflow: scroll;
    scroll-behavior: auto;
}

.tag_modal_list {
    max-height: 135px;
}

p {
    margin: 0.1em;
}

.tt_suggestion {
    padding: 0 10px;
}

.tt_suggestion_none {
    display: none;
}

.tt_suggestion:hover {
    background-color: #884a9d;
    padding: 0 10px;
    color: #ffffff;
}

.tag_input {
    position: absolute;
    top: 0px;
    left: 0px;
    border-color: transparent;
    box-shadow: none;
    opacity: 1;
    background: none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255);
}

.canned_response_list {
    bottom: calc(100% - 10px);
    left: 10px;
    position: absolute;
    z-index: 2000;
    box-shadow: 0 1px 10px 0 rgba(66,77,87,.3);
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 168px;
    display: block;
}

.canned_response_view {
    overflow: auto;
    display: block;
    color: rgb(66, 77, 87);
    border-radius: 4px;
}

.canned_response_item {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 22px;
    max-width: 360px;
    min-width: 90px;
    padding: 0px 10px;
    font-family: 'Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif';
    -webkit-font-smoothing: antialiased;
}

.canned_response_item:hover{
    background-color: rgb(225, 233, 236);
}

.canned_response_text {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    width: 100%;
    overflow: hidden;
    padding: 0px 10px;
}

.canned_response_arrow {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 4px;
    height: 8px;
    line-height: 8px;
    position: relative;
    top: 7px;
    margin-left: auto;
}

.canned_response_selected_text {
    max-height: 352px;
    display: block;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.canned_response_selected_text_li {
    list-style: none;
    padding: 10px;
    cursor: pointer;
    line-height: 1.3;
    font-size: 14px;
    color: #424d59;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.canned_response_selected_text_li:focus {
    background-color: #e1e9ec;
}

.canned_response_content {
    max-width: 360px;
    width: 100%;
    display: flex;
}

.canned_response_content_inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}

.canned_response_content_data {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 22px;
    max-width: 360px;
    min-width: 90px;
    padding: 0px 10px;
}

.canned_response_content_text {
    white-space: pre-wrap;
}

.canned_response_back_arrow {
    padding-right: 10px;
    height: 22px;
}

.canned_response_back_arrow_view {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 4px;
    height: 8px;
    line-height: 8px;
    position: relative;
    top: 7px;
    transform: rotate(180deg);
    -webkit-font-smoothing: antialiased;
}
.empty_chat_list{
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: 'HarmoniaSansStd-Bold';
    font-weight: 600;
}
.name_row, .info_row, .info_middle_row{
    display: flex;
    flex-direction: row;
}
.info_row_grid{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 15px;
}
.info_row, .info_row_grid{
    padding: 6px 5px;
    justify-content: space-between;
}
.col_4{
    padding-right: 8px;
}
.info_middle_row{
    padding: 6px 25px 6px 5px;
    justify-content: space-between;
}
.info_row{
    text-align: left;
    padding-left: 13px;
    padding-right: 8px;
}

/* --------------- loader to show messages --------------- */

.no_loading {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
}

.no_loading_view {
    width: 56px;
    height: 56px;
    display: flex;
}

.loading_loader {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: #884a9d;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

.SOAP_div{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.SOAP_heading{
    font-size: 18px;
    font-family: "HarmoniaSansStd-Bold";
    color: #000000;
    margin-bottom: 10px;
}
.SOAP_textarea{
    border: #E3E3EA 1px solid;
    border-radius: 5px;
    font-size: 16px;
    font-family: "HarmoniaSansStd-Regular";
    color: black;
    min-height: 111px;
    padding: 2px 10px;
    width: calc(100% - 20px);
}

.SOAP_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.SOAP_list_text{
    background-color: #4384F5;
    color: white;
    width: 96px;
    height: 21px;
    border-radius: 4px;
    margin-top: 10px;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "HarmoniaSansStd-Regular";
}
.SMSModal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 50%;
    border: 1px solid #1d1d1d;
    border-radius: 10px;
    padding: 35px;
    left: 25%;
    top: 0%;
    max-height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
}

.modalButtons{
    display: flex;
    justify-content: center;
}
.SaveModalButton {
    background-color: #007bef;
    color: #ffffff;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    margin: 10px;
}

.callsButton{
    cursor: pointer;
    background-color: #007bef;
    color: #ffffff;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
}
.callButtonsSection{
    display: flex;
    justify-content: space-evenly;
}
.cancelModalButton {
    background-color: #ececec;
    color: #222222;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #999999;
    margin: 10px;
}

.imageWidth {
    width: 155px;
    /*height: 112px;
    /margin: 15px;*/
}
.fileIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}
.iconBubble{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mainUserDetail {
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
}

.container {
    width: 100%;
    height: 100%;
    display: block;
}

.content_container {
    height: 100%;
    display: flex;
    position: relative;
}

.main_content_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #F1F2F6;
    flex: 1 0 auto;
}

.detailed_view {
    height: 100%;
    overflow: auto;
}

.detailed_view_container {
    padding: 15px 10px 100px;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
